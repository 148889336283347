<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Personal contact information</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-1 form-group required">
              <label
                class="form-label"
                for=""
              >Email </label>
              <input
                v-model="user.personal_email"
                type="email"
                class="form-control"
                placeholder="name@domain.com"
              >
            </div>
          </div>
          <!-- <div class="col-md-15">
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Prefix</label>
              <v-select
                v-if="loggedUser.roles.includes('super-admin')"
                v-model="user.phone_prefix_aux"
                label="phone_code"
                :options="citizenships"
                :get-option-key="option => option.id"
                :clearable="false"
              />
              <div v-else>
                {{ user.phone_prefix_aux }}
              </div>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="mb-1 form-group required">
              <label
                class="form-label"
                for=""
              >Mobile phone </label>
              <input
                v-model="user.phone"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Fix phone </label>
              <input
                v-model="user.fix_phone"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Website </label>
              <input
                v-model="user.website"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <!-- <div class="col-md-15">
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Prefix</label>
              <v-select
                v-if="loggedUser.roles.includes('super-admin')"
                v-model="user.work_phone_prefix_aux"
                label="phone_code"
                :options="citizenships"
                :clearable="false"
                :get-option-key="option => option.id"
              />
              <div v-else>
                {{ user.work_phone_prefix_aux }}
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Work phone </label>
              <input
                v-if="loggedUser.roles.includes('super-admin')"
                v-model="user.work_phone"
                type="text"
                class="form-control"
              >
              <div v-else>
                {{ user.work_phone }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  computed: {
    ...mapGetters({
      citizenships: 'citizenships/items',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>

<style>

</style>
