<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">New ICREA</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 align-items-center form-switch d-flex ps-0">
            <div class="mb-1">
              <input
                id="publishContent1"
                v-model="user.new_icrea"
                type="checkbox"
                class="form-check-input ms-2"
                value="1"
                @click="checkEndDate"
              >
              <label
                class="form-check-label"
                for="publishContent1"
              ><small class="ms-1">{{ labels['mark_new_icrea'] }} </small></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >{{ labels['end_date'] }}</label>
              <date-picker
                v-model="user.date_finish_new_icrea"
                :disabled="!user.new_icrea"
                format="D MMM Y"
                value-type="format"
                class="w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    DatePicker,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      labels: [],
    }
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.new_icrea`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    checkEndDate(event) {
      if (!event.target.checked) {
        // If the event
        this.user.date_finish_new_icrea = null
        this.user.new_icrea_text = null
      }
    },
  },
}
</script>
