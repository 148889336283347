<template>
  <!-- Personal data -->
  <div
    id="personal-data"
    class="tab-pane active"
    aria-labelledby="personal-data-tab"
    role="tabpanel"
  >
    <div
      class="row"
    >
      <div class="col-lg-4 col-sm-6">
        <PersonalInformation
          :user="loggedUser"
        />
      </div>
      <div class="col-lg-4 col-sm-6">
        <UserAddress
          :user="loggedUser"
        />
      </div>
      <div class="col-lg-4 col-sm-6">
        <ContactInformation
          :user="loggedUser"
        />
      </div>
    </div>
    <div
      v-if="log.roles.includes('super-admin')"
      class="row"
    >
      <div class="col-6">
        <NewIcrea
          :user="loggedUser"
        />
      </div>
      <div
        v-if="log.roles.includes('super-admin')"
        class="col-6"
      >
        <NewIcreaText
          :user="loggedUser"
        />
      </div>
      <div class="col-6">
        <UserCard
          :user="loggedUser"
        />
      </div>
      <div
        v-if="log.roles.includes('super-admin')"
        class="col-6"
      >
        <IcreaStatus
          :user="loggedUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalInformation from '@/views/back/partials/components/PersonalInformation.vue'
import NewIcrea from '@/views/back/partials/components/NewIcrea.vue'
import ContactInformation from '@/views/back/partials/components/ContactInformation.vue'
import UserAddress from '@/views/back/partials/components/UserAddress.vue'
import UserCard from '@/views/back/partials/components/UserCard.vue'
import IcreaStatus from '@/views/back/partials/components/IcreaStatus.vue'
import NewIcreaText from '@/views/back/partials/components/NewIcreaText.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    PersonalInformation,
    NewIcrea,
    ContactInformation,
    UserAddress,
    UserCard,
    IcreaStatus,
    NewIcreaText,
  },
  props: {
    loggedUser: { type: Object, required: true, default: () => {} },
  },
  computed: {
    ...mapGetters({
      citizenships: 'citizenships/items',
      log: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('citizenships/fetch')
    await this.$store.dispatch('cardTypes/fetchActives')
    await this.$store.dispatch('icreaStatus/fetch')
    await this.$store.dispatch('genres/fetch')
  },
}
</script>

<style>

</style>
