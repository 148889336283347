<template>
  <div class="card">
    <div
      v-b-toggle="`collapseShortBigraphy`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Short biography <span class="text-danger">*</span></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseShortBigraphy`"
        visible
      >
        <div class="card-body pt-0">
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label disp-bl"
                >{{ labels['content'] }} <span class="text-danger float-end">{{ contentLength }}/1000</span></label>
                <div id="full-wrapper">
                  <div id="full-container">
                    <quill-editor
                      ref="quillTest"
                      v-model="user.short_biography"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      contentLength: 0,
      labels: [],
    }
  },
  computed: {
    checkCharacterCounter() {
      return this.user.short_biography
    },
  },
  watch: {
    checkCharacterCounter() {
      this.$refs.quillTest.quill.deleteText(1000, this.$refs.quillTest.quill.getLength() - 1)
      this.contentLength = this.$refs.quillTest.quill.getLength() - 1
      if (this.$refs.quillTest.quill.getLength() - 1 === 0) {
        this.user.short_biography = null
      }
    },
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.short_biography`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }

    this.contentLength = this.$refs.quillTest.quill.getLength() - 1
    if (this.$refs.quillTest.quill.getLength() - 1 === 0) {
      this.user.short_biography = null
    }
  },
  methods: {
    textChanged($event) {
      if ($event.editor.getLength() > 1000) {
        $event.editor.deleteText(1000, $event.editor.getLength() - 1)
      }
    },
  },

}
</script>

<style>

</style>
