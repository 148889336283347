<template>
  <div
    id="short-bio"
    class="tab-pane"
    aria-labelledby="short-bio-tab"
    role="tabpanel"
  >
    <div class="row">
      <div class="col-4">
        <AreaIcrea
          :user="loggedUser"
        />
      </div>
      <div class="col-8">
        <Keywords
          :user="loggedUser"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <MainERC
          :user="loggedUser"
        />
      </div>
      <div class="col-6">
        <ListOtherERC
          :user="loggedUser"
        />
      </div>
    </div>

    <ShortBiography
      :user="loggedUser"
    />

    <ResearchInterest
      :user="loggedUser"
    />

    <IdentificationCodes
      :user="loggedUser"
    />
  </div>
</template>

<script>
import AreaIcrea from '@/views/back/partials/components/profile/AreaIcrea.vue'
import ShortBiography from '@/views/back/partials/components/profile/ShortBiography.vue'
import ResearchInterest from '@/views/back/partials/components/profile/ResearchInterest.vue'
import Keywords from '@/views/back/partials/components/profile/Keywords.vue'
import MainERC from '@/views/back/partials/components/profile/MainERC.vue'
import ListOtherERC from '@/views/back/partials/components/profile/ListOtherERC.vue'
import IdentificationCodes from '@/views/back/partials/components/profile/IdentificationCodes.vue'

export default {
  components: {
    AreaIcrea,
    ShortBiography,
    ResearchInterest,
    Keywords,
    MainERC,
    ListOtherERC,
    IdentificationCodes,
  },
  props: {
    loggedUser: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    await this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    this.loading = false
  },

}
</script>

<style>

</style>
