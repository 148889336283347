<template>
  <div
    v-if="user"
    class="card"
  >
    <div
      v-b-toggle="`collapsePersonalInfo`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Personal information</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsePersonalInfo`"
      visible
    >
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >{{ labels['name'] }}</label>
                <input
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.first_name"
                  type="text"
                  class="form-control"
                >
                <div v-else>
                  {{ user.first_name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >{{ labels['middle_initial'] }}</label>
                <input
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.middle_initial"
                  type="text"
                  class="form-control"
                >
                <div v-else>
                  {{ user.middle_initial }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >{{ labels['last_name'] }}</label>
                <input
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.surname_1"
                  type="text"
                  class="form-control"
                >
                <div v-else>
                  {{ user.surname_1 }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >{{ labels['second_last_name'] }}</label>
                <input
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.surname_2"
                  type="text"
                  class="form-control"
                >
                <div v-else>
                  {{ user.surname_2 }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['citizenship_1'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.citizenship1"
                  label="name"
                  :options="citizenships"
                  :get-option-key="option => option.id"
                />
                <div
                  v-else
                >{{ user.citizenship1 ? user.citizenship1.name : '' }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >{{ labels['citizenship_2'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.citizenship2"
                  label="name"
                  :options="citizenships"
                  :get-option-key="option => option.id"
                />
                <div v-else>
                  {{ user.citizenship2 ? user.citizenship2.name : '' }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['birth'] }}</label>
                <date-picker
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.birthday"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
                <div v-else>
                  {{ user.birthday }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >{{ labels['gender'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.genre"
                  label="name"
                  :options="genders"
                  :get-option-key="option => option.id"
                />
                <div v-else>
                  {{ user.genre ? user.genre.name : '' }}
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="mb-1">
                <label
                  class="form-label"
                  for=""
                >Identify document</label>
                <input
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="user.identify_document"
                  type="text"
                  class="form-control"
                >
                <div v-else>
                  {{ user.identify_document }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Password</label>
                <input
                  v-model="user.password"
                  class="form-control"
                  type="password"
                >
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    DatePicker,
    BCollapse,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      citizenships: 'citizenships/items',
      genders: 'genres/items',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.personal_information`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style>

</style>
