<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">ICREA status</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-1 form-group">
              <v-select
                v-model="user.icreaStatus"
                label="name"
                :options="status"
                :get-option-key="option => option.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  computed: {
    ...mapGetters({
      status: 'icreaStatus/items',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>
