<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">Personal data, short biography and research interest<br>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="userIdSelected" />

            <div
              v-if="user.id"
              class="tab-content"
            >
              <b-tabs>
                <b-tab
                  :active="!$route.query.goTo"
                >
                  <template #title>
                    Personal data
                  </template>
                  <!-- Personal data -->
                  <div
                    v-if="loading"
                    class="loading position-relative"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                  <PersonalData
                    v-else
                    :logged-user="user"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    Short biography & Research interests
                  </template>
                  <!-- Short biography & Research interests -->
                  <div
                    v-if="loading"
                    class="loading position-relative"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                  <ShortBiographyProfile
                    v-else
                    :logged-user="user"
                  />
                </b-tab>
                <b-tab :active="$route.query.goTo == 'cv'">
                  <template #title>
                    CV
                  </template>
                  <!-- CV -->
                  <div
                    v-if="loading"
                    class="loading position-relative"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                  <template v-else>
                    <div class="row">
                      <div class="col-6">
                        <CV
                          v-if="!loading"
                          :logged-user="user"
                          :title="'Full CV'"
                          :type="1"
                          :secondary="secondaryCV || forceSecondary"
                          @hideCv="hideCv"
                        />
                      </div>
                      <div
                        v-if="secondaryCV || forceSecondary"
                        class="col-6"
                      >
                        <CV
                          v-if="!loading"
                          :logged-user="user"
                          :title="'Secondary CV'"
                          :type="2"
                          :secondary="secondaryCV"
                        />
                      </div>
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
              <hr>
              <div
                v-if="loading"
                class="loading position-relative"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
              <template
                v-else
              >
                <!-- <IcreaRemarks
                  :item="user"
                  :show="true"
                /> icreas remark son un comentario que admin deja al icrea como anotación, el icrea puede leerlo pero no editarlo -->

                <InternalNotes
                  :notes="user.notes"
                  @saveNewNote="saveNewNote"
                />
              </template>
            </div>
          </div>

          <div
            v-if="user.id"
            class="page-actions"
          >
            <button
              v-if="!sending"
              id="type-success"
              class="btn btn-success"
              @click="save"
            >
              <i data-feather="save" /> Save and Exit
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Saving...</span>
            </button>
          </div>

        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import InternalNotes from '../../partials/components/InternalNotes.vue'
import PersonalData from './components/PersonalData.vue'
import ShortBiographyProfile from './components/ShortBiographyProfile.vue'
import CV from './components/CV.vue'

export default {
  components: {
    SearchResearcher,
    PersonalData,
    ShortBiographyProfile,
    CV,
    InternalNotes,
    BTab,
    BTabs,
  },
  data() {
    return {
      userIdSelected: {},
      loading: true,
      sending: false,
      selectedRoles: [],
      rolesIds: [],
      forceSecondary: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
    userSelected() {
      return this.userIdSelected.user
    },
    secondaryCV() {
      return this.user.CVFile != null && this.user.CVFile.filter(e => e.type == 6).length > 0
    },
  },
  watch: {
    async userSelected() {
      this.loading = true
      if (this.userIdSelected.user) {
        await this.$store.dispatch('users/fetchUser', this.userIdSelected.user.id)
      } else {
        await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
      }
      this.loading = false
    },
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'my_profile.personal_data')
    if (this.loggedUser.roles.includes('super-admin')) {
      if (this.$route.query.prefilterUser) {
        await this.$store.dispatch('users/fetchUser', this.$route.query.prefilterUser)
      }
    } else {
      await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
    }

    this.selectedRoles = this.user.roles
    this.loading = false
  },
  methods: {
    async save() {
      try {
        if (this.user.id) {
          this.user.additional_permissions = this.user.direct_permissions
          this.user.personalData = true

          // if (typeof this.user.country === 'object') {
          //   this.user.country = this.user.country.id
          // }

          if (this.forceSecondary && !this.secondaryCV) {
            Vue.swal('Check your Secondary CV', '', 'warning')
          } else {
            await this.$store.dispatch('users/update', { id: this.user.id, data: this.user })
          }
        }

        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.forceSecondary && !this.secondaryCV) {
        // Vue.swal('Check your Secondary CV', '', 'warning')
      } else if (this.errors === '' && this.user.id) {
        this.loading = true
        Vue.swal('The user has been saved correctly!', '', 'success').then(async () => {
          this.$router.push({ name: 'researcher.personal-data.index' })
          await this.$store.dispatch('users/fetchUser', this.user.id)
          this.selectedRoles = this.user.roles
          this.loading = false
        })
      } else {
        // Vue.swal
      }

      this.loading = false
      this.sending = false
    },
    saveNewNote(note) {
      this.user.notes.push(note)
    },
    saveAnswer(data) {
      this.user.other_cvs = data
    },
    hideCv() {
      if (this.secondary) {
        this.forceSecondary = false
      } else {
        this.forceSecondary = !this.forceSecondary
      }
    },
  },
}
</script>

<style>

</style>
